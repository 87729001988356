/* html {
  direction: rtl;
} */
@font-face {
  font-family: "Geomanist-Bold";
  src: url("./assets/fonts/Geomanist-Bold.ttf");
}

@font-face {
  font-family: "Geomanist-Regular";
  src: url("./assets/fonts/Geomanist-Regular.ttf");
}

@font-face {
  font-family: "Gilory-Medium";
  src: url("./assets/fonts/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "Gilory-SemiBold";
  src: url("./assets/fonts/Gilroy-SemiBold.ttf");
}

@font-face {
  font-family: "Gilory-Bold";
  src: url("./assets/fonts/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "Gilory-ExtraBold";
  src: url("./assets/fonts/Gilroy-ExtraBold.otf");
}

@font-face {
  font-family: "Gilory-Black";
  src: url("./assets/fonts/Gilroy-Black.ttf");
}

@font-face {
  font-family: "Gilory-Regular";
  src: url("./assets/fonts/Gilroy-Regular.ttf");
}

@font-face {
  font-family: "Cairo-Medium";
  src: url("./assets/fonts/Cairo-Medium.ttf");
}

@font-face {
  font-family: "Cairo-SemiBold";
  src: url("./assets/fonts/Cairo-SemiBold.ttf");
}

@font-face {
  font-family: "Cairo-Bold";
  src: url("./assets/fonts/Cairo-Bold.ttf");
}

@font-face {
  font-family: "Cairo-ExtraBold";
  src: url("./assets/fonts/Cairo-ExtraBold.ttf");
}

@font-face {
  font-family: "Cairo-Black";
  src: url("./assets/fonts/Cairo-Black.ttf");
}

@font-face {
  font-family: "Cairo-Regular";
  src: url("./assets/fonts/Cairo-Regular.ttf");
}

body {
  --dana-color: #9e0a34;

  margin: 0 auto;

  font-family: "Cairo-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Cairo-Regular", sans-serif;
}
